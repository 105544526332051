import { useQuery } from '@tanstack/react-query'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import React, { useState } from 'react'

import { IWalletMetrics } from '@/types/metrics.interface'

import Chart from './Chart'
import { defaultDatasetOptions } from './default-dataset-options'
import api from '@/api'
import { mstasToBtcAndFormat } from '@/utils'

interface Props {
  wallet: 'stash' | 'flash' | 'starter'
}

const WalletMetricsChart: React.FC<Props> = ({ wallet }) => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['wallet-chart', wallet, range],
    queryFn: async () =>
      await api.get<{
        data: (IWalletMetrics & { timestamp: string })[]
      }>(`/admin/metrics/charts/${wallet}?range=${range}`),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const totalBalanceMsats = rawData.map(d => d.total_balance_msats)
      const totalBalanceUsd = rawData.map(d => d.total_balance_usd)
      const volumeSentBtc = rawData.map(d =>
        Number(mstasToBtcAndFormat(d.volume_sent_msats_all))
      )
      const volumeSentUsd = rawData.map(d => d.volume_sent_usd_all)
      const volumeReceivedBtc = rawData.map(d =>
        Number(mstasToBtcAndFormat(d.volume_received_msats_all))
      )
      const volumeReceivedUsd = rawData.map(d => d.volume_received_usd_all)
      const paymentsSent = rawData.map(d => d.payments_sent_all)
      const paymentsReceived = rawData.map(d => d.payments_received_all)

      const chartData: ChartData<'line'> = {
        labels,
        datasets: [
          {
            label: 'Total Balance (BTC)',
            data: totalBalanceMsats,
            ...defaultDatasetOptions(),
            hidden: false
          },
          {
            label: 'Total Balance (USD)',
            data: totalBalanceUsd,
            ...defaultDatasetOptions()
          },
          {
            label: 'Volume Sent (BTC)',
            data: volumeSentBtc,
            ...defaultDatasetOptions()
          },
          {
            label: 'Volume Sent (USD)',
            data: volumeSentUsd,
            ...defaultDatasetOptions()
          },
          {
            label: 'Volume Received (BTC)',
            data: volumeReceivedBtc,
            ...defaultDatasetOptions()
          },
          {
            label: 'Volume Received (USD)',
            data: volumeReceivedUsd,
            ...defaultDatasetOptions()
          },
          {
            label: 'Payments Sent',
            data: paymentsSent,
            ...defaultDatasetOptions()
          },
          {
            label: 'Payments Received',
            data: paymentsReceived,
            ...defaultDatasetOptions()
          }
        ]
      }

      return chartData
    }
  })

  return (
    <Chart
      title={`${wallet[0].toLocaleUpperCase() + wallet.slice(1)} Wallet Metrics`}
      setSelectedTab={setRange}
      selectedTab={range}
      isLoading={isLoading}
      isError={isError}
      data={data}
    />
  )
}

export default WalletMetricsChart
