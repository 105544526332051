import { useState } from 'react'

import Page from '@/components/Page'
import ProtectedComponent from '@/components/Protected'
import UsersMetrics from '@/components/features/Metrics/Users'
import SelectCountryIso, {
  CountryIso
} from '@/components/features/SelectCountryIso'
import SelectKycStatus from '@/components/features/SelectKycStatus'
import { DataTable } from '@/components/ui/DataTable'

import { KycStatus } from '@/types/enums'

import Export from './Export'
import { columns } from './columns'
import { USERS_TABS } from '@/constants/tabs'

const UsersPage: React.FC = () => {
  const [kycStatus, setKycStatus] = useState<KycStatus | 'all' | undefined>(
    undefined
  )
  const [country, setCountry] = useState<CountryIso | 'all' | undefined>(
    undefined
  )

  return (
    <Page
      pageTitle='Users'
      tabs={USERS_TABS}
      header={
        <ProtectedComponent permissionSlug='users_view'>
          <Export />
        </ProtectedComponent>
      }
    >
      <UsersMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='users'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        body={{ status: kycStatus, country }}
        method='POST'
      >
        <SelectKycStatus kycStatus={kycStatus} setKycStatus={setKycStatus} />
        <SelectCountryIso country={country} setCountry={setCountry} />
      </DataTable>
    </Page>
  )
}

export default UsersPage
