import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import Page from '@/components/Page'
import BackButton from '@/components/ui/ButtonBack'
import { DataTable } from '@/components/ui/DataTable'

import { ISTBAService } from '@/types/stba-service.interface'

import { columns } from './columns'
import api from '@/api'

const StbaServiceByIdPage: React.FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: [`/admin/stba-service/${id}`],
    queryFn: async () => {
      const resp = await api.get<ISTBAService>(`/admin/stba-service/${id}`)

      return resp.data
    }
  })

  return (
    <Page
      permissionSlug='services_view'
      pageTitle='STBA Service'
      title={isLoading ? 'Loading...' : `${data?.name}`}
      header={<BackButton href='/organization/stba-services' />}
    >
      {data && data.logs ? (
        <DataTable
          url='/admin/logs'
          title='Logs'
          data={data.logs}
          columns={columns}
          totalText='logs'
        />
      ) : (
        <p>No logs found.</p>
      )}
    </Page>
  )
}

export default StbaServiceByIdPage
