import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import {
  ICreateOrUpdateSTBAService,
  ISTBAService
} from '@/types/stba-service.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: ISTBAService
}

const CreateOrUpdateService: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateSTBAService>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          name: initial.name,
          description: initial.description,
          aws_instance_id: initial.aws_instance_id,
          base_url: initial.base_url,
          status_endpoint: initial.status_endpoint,
          logs_endpoint: initial.logs_endpoint
        }
      : {
          name: '',
          description: '',
          aws_instance_id: '',
          base_url: '',
          status_endpoint: '',
          logs_endpoint: ''
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            name: initial.name,
            description: initial.description,
            aws_instance_id: initial.aws_instance_id,
            base_url: initial.base_url,
            status_endpoint: initial.status_endpoint,
            logs_endpoint: initial.logs_endpoint
          }
        : {
            name: '',
            description: '',
            aws_instance_id: '',
            base_url: '',
            status_endpoint: '',
            logs_endpoint: ''
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-stba-service' : 'add-stba-service'],
    mutationFn: (data: ICreateOrUpdateSTBAService) =>
      api(
        initial ? `/admin/stba-service/${initial.id}` : '/admin/stba-service',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(`Service ${initial ? 'updated' : 'created'} successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} Service`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateSTBAService> = async data =>
    mutate(data)

  return (
    <ProtectedComponent
      permissionSlug={initial ? 'services_update' : 'services_create'}
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Service'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit Stba Service' : 'Add Stba Service'}
            </DialogTitle>
            <DialogDescription>
              {initial ? 'Edit service details' : 'Add a new Stba Service'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Name'
              placeholder='Name'
              {...register('name', { required: 'Name is required' })}
              error={errors.name?.message}
            />
            <Field
              title='Description'
              placeholder='Description'
              {...register('description')}
              error={errors.description?.message}
            />
            <Field
              title='AWS Instance ID'
              placeholder='AWS Instance ID'
              {...register('aws_instance_id', {
                pattern: {
                  value: /^[i]-[a-z0-9]{17}$/,
                  message: 'Invalid AWS Instance ID'
                }
              })}
              error={errors.aws_instance_id?.message}
            />
            <Field
              title='Base URL'
              placeholder='Base URL'
              {...register('base_url', { required: 'Base URL is required' })}
              error={errors.base_url?.message}
            />
            <Field
              title='Status Endpoint'
              placeholder='Status Endpoint'
              {...register('status_endpoint')}
              error={errors.status_endpoint?.message}
            />
            <Field
              title='Logs Endpoint'
              placeholder='Logs Endpoint'
              {...register('logs_endpoint')}
              error={errors.logs_endpoint?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateService
