export interface ITab {
  url: string
  name: string
  permissionSlug?: string
}

export const ORGANIZATION_TABS: ITab[] = [
  {
    url: '/organization/members',
    name: 'Team Members',
    permissionSlug: 'organization_view'
  },
  {
    url: '/organization/roles',
    name: 'Roles',
    permissionSlug: 'roles_view'
  },
  {
    url: '/organization/configurations',
    name: 'Configurations',
    permissionSlug: 'configurations_view'
  },
  {
    url: '/organization/ip-addresses',
    name: "IP's",
    permissionSlug: 'ip_addresses_view'
  },
  {
    url: '/organization/stba-services',
    name: 'STBA Services',
    permissionSlug: 'services_view'
  }
]

export const USERS_TABS: ITab[] = [
  {
    url: '/users',
    name: 'Users'
  },
  {
    url: '/users/access-codes',
    name: 'Access Codes',
    permissionSlug: 'access_codes_view'
  },
  {
    url: '/users/groups',
    name: 'Groups',
    permissionSlug: 'account_groups_view'
  },
  {
    url: '/users/username-blacklist',
    name: '@Blacklist',
    permissionSlug: 'username_blacklist_view'
  },
  {
    url: '/users/email-whitelist',
    name: 'Whitelist',
    permissionSlug: 'email_whitelist_view'
  }
]

export const LEARN_TABS: ITab[] = [
  {
    url: '/learn/creators',
    name: 'Creators',
    permissionSlug: 'learn_view'
  },
  {
    url: '/learn/categories',
    name: 'Categories',
    permissionSlug: 'learn_view'
  },
  {
    url: '/learn/videos',
    name: 'Videos',
    permissionSlug: 'learn_view'
  }
]

export const METRICS_TABS: ITab[] = [
  {
    url: '/metrics',
    name: 'Overview',
    permissionSlug: 'metrics_view'
  },
  {
    url: '/metrics/charts',
    name: 'Charts',
    permissionSlug: 'metrics_view'
  }
]

export const EARN_TABS: ITab[] = [
  {
    url: '/earn/users',
    name: 'Users',
    permissionSlug: 'earn_view'
  },
  {
    url: '/earn/transactions',
    name: 'Transactions',
    permissionSlug: 'earn_view'
  }
]

export const REFER_TABS: ITab[] = [
  {
    url: '/refer/users',
    name: 'Users',
    permissionSlug: 'refer_view'
  },
  {
    url: '/refer/claims',
    name: 'Claims',
    permissionSlug: 'refer_view'
  },
  {
    url: '/refer/transactions',
    name: 'Transactions',
    permissionSlug: 'refer_view'
  }
]

export const TRANSFERS_TABS: ITab[] = [
  {
    url: '/transfers/exchange',
    name: 'Exchange',
    permissionSlug: 'transfers_view'
  },
  {
    url: '/transfers/flash',
    name: 'Flash',
    permissionSlug: 'transfers_view'
  },
  {
    url: '/transfers/stash',
    name: 'Stash',
    permissionSlug: 'transfers_view'
  },
  {
    url: '/transfers/Starter',
    name: 'Starter',
    permissionSlug: 'transfers_view'
  },
  {
    url: '/transfers/onchain',
    name: 'Onchain'
  }
]

export const BANK_TABS: ITab[] = [
  {
    url: '/bank/kyc',
    name: 'KYC',
    permissionSlug: 'kyc_verifications_view'
  },
  {
    url: '/bank/payment-methods',
    name: 'Payment Methods',
    permissionSlug: 'bank_connection_view'
  },
  {
    url: '/bank/trades',
    name: 'Trades',
    permissionSlug: 'bank_trades_view'
  },
  {
    url: '/bank/fundings',
    name: 'Fundings',
    permissionSlug: 'bank_fundings_view'
  },
  {
    url: '/bank/edd-requests',
    name: 'EDD Requests',
    permissionSlug: 'edd_requests_view'
  }
]
