import { useQuery } from '@tanstack/react-query'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import React, { useState } from 'react'

import { IExchangeMetrics } from '@/types/metrics.interface'

import Chart from './Chart'
import { defaultDatasetOptions } from './default-dataset-options'
import api from '@/api'

const ExchangeMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['exchangeMetrics', range],
    queryFn: async () =>
      await api.get<{
        data: (IExchangeMetrics & { timestamp: string })[]
      }>(`/admin/metrics/charts/exchange?range=${range}`),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const custodiedBtc = rawData.map(d => d.custodied_btc)
      const custodiedUsd = rawData.map(d => d.custodied_usd)

      const chartData: ChartData<'line'> = {
        labels,
        datasets: [
          {
            label: 'BTC Under Custody',
            data: custodiedBtc,
            ...defaultDatasetOptions()
          },
          {
            label: 'USD Under Custody',
            data: custodiedUsd,
            ...defaultDatasetOptions()
          },
          {
            label: 'Buy Volume Daily',
            data: rawData.map(d => d.trades_buy_usd_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Volume Daily',
            data: rawData.map(d => d.trades_sell_usd_24h),
            ...defaultDatasetOptions
          },
          {
            label: 'Buy Volume Weekly',
            data: rawData.map(d => d.trades_buy_usd_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Volume Weekly',
            data: rawData.map(d => d.trades_sell_usd_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Buy Volume All',
            data: rawData.map(d => d.trades_buy_usd_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Volume All',
            data: rawData.map(d => d.trades_sell_usd_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Volume Daily',
            data: rawData.map(d => d.withdrawal_usd_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Volume Weekly',
            data: rawData.map(d => d.withdrawal_usd_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Volume All',
            data: rawData.map(d => d.withdrawal_usd_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Volume Daily',
            data: rawData.map(d => d.deposit_ach_usd_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Volume Weekly',
            data: rawData.map(d => d.deposit_ach_usd_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Volume All',
            data: rawData.map(d => d.deposit_ach_usd_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Volume Daily',
            data: rawData.map(d => d.deposit_wire_usd_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Volume Weekly',
            data: rawData.map(d => d.deposit_wire_usd_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Volume All',
            data: rawData.map(d => d.deposit_wire_usd_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Buy Count Daily',
            data: rawData.map(d => d.trades_buy_count_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Count Daily',
            data: rawData.map(d => d.trades_sell_count_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Buy Count Weekly',
            data: rawData.map(d => d.trades_buy_count_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Count Weekly',
            data: rawData.map(d => d.trades_sell_count_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Buy Count All',
            data: rawData.map(d => d.trades_buy_count_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Sell Count All',
            data: rawData.map(d => d.trades_sell_count_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Count Daily',
            data: rawData.map(d => d.withdrawal_count_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Count Weekly',
            data: rawData.map(d => d.withdrawal_count_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Withdrawal Count All',
            data: rawData.map(d => d.withdrawal_count_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Count Daily',
            data: rawData.map(d => d.deposit_ach_count_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Count Weekly',
            data: rawData.map(d => d.deposit_ach_count_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (ACH) Count All',
            data: rawData.map(d => d.deposit_ach_count_all),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Count Daily',
            data: rawData.map(d => d.deposit_wire_count_24h),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Count Weekly',
            data: rawData.map(d => d.deposit_wire_count_week),
            ...defaultDatasetOptions()
          },
          {
            label: 'Deposit (Wire) Count All',
            data: rawData.map(d => d.deposit_wire_count_all),
            ...defaultDatasetOptions()
          }
        ]
      }

      return chartData
    }
  })

  return (
    <Chart
      title='Exchange Metrics'
      setSelectedTab={setRange}
      selectedTab={range}
      isLoading={isLoading}
      isError={isError}
      data={data}
    />
  )
}

export default ExchangeMetricsChart
