import { useQuery } from '@tanstack/react-query'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import React, { useState } from 'react'

import { IAccountMetrics } from '@/types/metrics.interface'

import Chart from './Chart'
import { defaultDatasetOptions } from './default-dataset-options'
import api from '@/api'

const AccountsMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['accountsMetrics', range],
    queryFn: async () =>
      await api.get<{
        data: (IAccountMetrics & { timestamp: string })[]
      }>(`/admin/metrics/charts/accounts?range=${range}`),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const activeLastHour = rawData.map(d => d.active_1h)
      const activeLastDay = rawData.map(d => d.active_24h)
      const activeLastWeek = rawData.map(d => d.active_week)
      const total = rawData.map(d => d.created_all)
      const totalReferred = rawData.map(d => d.referred_all)
      const totalSatoshiPlus = rawData.map(d => d.satoshi_plus_all)
      const totalDownloadsAPK = rawData.map(d => d.downloads_apk_all)
      const totalDownloadsAppStore = rawData.map(d => d.downloads_apple_all)
      const totalDownloadsPlayStore = rawData.map(d => d.downloads_google_all)

      const chartData: ChartData<'line'> = {
        labels,

        datasets: [
          {
            label: 'Active Last Hour',
            data: activeLastHour,
            ...defaultDatasetOptions()
          },
          {
            label: 'Active Users (Day)',
            data: activeLastDay,
            ...defaultDatasetOptions()
          },
          {
            label: 'Active Users (Week)',
            data: activeLastWeek,
            ...defaultDatasetOptions()
          },
          {
            label: 'Total',
            data: total,
            ...defaultDatasetOptions(),
            hidden: false
          },
          {
            label: 'Total Referred',
            data: totalReferred,
            ...defaultDatasetOptions()
          },
          {
            label: 'Total Downloads (APK)',
            data: totalDownloadsAPK,
            ...defaultDatasetOptions()
          },
          {
            label: 'Total Downloads (App Store)',
            data: totalDownloadsAppStore,
            ...defaultDatasetOptions()
          },
          {
            label: 'Total Downloads (Play Store)',
            data: totalDownloadsPlayStore,
            ...defaultDatasetOptions()
          },
          {
            label: 'Total Satoshi+',
            data: totalSatoshiPlus,
            ...defaultDatasetOptions()
          }
        ]
      }

      return chartData
    }
  })

  return (
    <Chart
      title='Accounts Metrics'
      setSelectedTab={setRange}
      selectedTab={range}
      isLoading={isLoading}
      isError={isError}
      data={data}
    />
  )
}

export default AccountsMetricsChart
