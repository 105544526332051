import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import ProtectedComponent from '@/components/Protected'
import UserCard from '@/components/features/UserCard'
import { Button } from '@/components/ui/Button'
import AnimatedLoader from '@/components/ui/Loader'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@/components/ui/Sheet'

import { IUser } from '@/types/account/user.interface'
import { AccountType, KycStatus } from '@/types/enums'

import formatId from '@/utils/formatId'
import { returnKycIcon } from '@/utils/return-kyc-icon'

import GetCountryNameByIsoCode from '../GetCountryNameFromIsoCode'

import UserActions from './Actions'
import { Section, SectionOption } from './Section'
import api from '@/api'
import { cn } from '@/utils'

interface Props {
  user: IUser
  fromOtherPage?: boolean
  small?: boolean
}

const UserDetailsModal: React.FC<Props> = ({
  user,
  fromOtherPage = false,
  small = false
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    data: userData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['user', user.id],
    queryFn: async () => {
      const resp = await api<IUser>(`/admin/users/${user.id}`)

      return resp.data
    },
    enabled: isOpen
  })

  const accountDetails = useMemo(() => {
    if (!userData) return []

    const details: SectionOption[] = [
      {
        label: 'Display Name',
        rightContent: userData.display_name || '-'
      },
      { label: 'Username', rightContent: `@${userData.username}` },
      { label: 'Email', rightContent: userData.email },
      {
        label: 'KYC Status',
        rightContent: (
          <p
            className={cn(
              'flex items-center gap-1 text-sm font-semibold text-lime500',
              {
                'text-destructive': userData.kyc_status === KycStatus.FAILED,
                'text-orange500': userData.kyc_status === KycStatus.STARTED,
                'text-foreground/50':
                  userData.kyc_status === KycStatus.UNVERIFIED
              }
            )}
          >
            {returnKycIcon(userData.kyc_status, 'size-5')}
            {userData.kyc_status}
          </p>
        )
      },
      {
        label: 'Membership Type',
        rightContent:
          userData.membership_type === 'REGULAR' ? 'Regular' : 'Satoshi Plus'
      },
      {
        label: 'Date Joined',
        rightContent: format(userData.created_at, 'yyyy.MM.dd HH:mm')
      },
      {
        label: 'Account Type',
        rightContent: userData.type
      },
      {
        label: 'Refered BY',
        rightContent: userData.referred_by ? (
          <UserDetailsModal fromOtherPage user={userData.referred_by} small />
        ) : (
          <p className='text-foreground/50'>No Referrer</p>
        )
      }
    ]

    details.push({
      label: 'Blocked',
      rightContent: 'Yes'
    })
    if (userData?.blocked) {
      details.push({
        label: 'Blocked Reason',
        rightContent: userData.blocked_reason || '-'
      })
    }

    if (userData?.type === AccountType.FLASH) {
      details.push({
        label: 'LNURL',
        rightContent: userData.flash_lnurl ? (
          <button
            onClick={() => {
              navigator.clipboard.writeText(`${userData.flash_lnurl}`)
              toast.success('ID Copied to clipboard')
            }}
          >
            {formatId(userData.flash_lnurl)}
          </button>
        ) : (
          <p className='text-foreground/50'>No LNURL found</p>
        )
      })
    }

    return details
  }, [userData])

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        {fromOtherPage ? (
          <UserCard
            name={user.display_name || `@${user.username}`}
            nameClassName='text-foreground'
            avatar={user.avatar || ''}
            onPress={() => setIsOpen(true)}
            small={small}
          />
        ) : (
          <Button variant={'edit'}>Edit</Button>
        )}
      </SheetTrigger>
      <SheetContent
        aria-description='details'
        className='h-screen w-full border-solid border-gray500/50 bg-gray800 sm:max-w-lg '
      >
        <section className='flex h-[calc(100svh-100px)] w-full flex-col gap-2 overflow-y-auto p-4 md:gap-4 md:p-6'>
          <SheetHeader>
            <SheetTitle className='text-[20px] font-bold tracking-normal text-white'>
              User Details
            </SheetTitle>
            <SheetDescription />
          </SheetHeader>
          {isLoading ? (
            <AnimatedLoader className='mx-auto' />
          ) : error ? (
            <div className='flex h-full flex-col items-center justify-center gap-1'>
              <p className='text-destructive'>
                An error occurred while fetching the user data.
              </p>
              <p>{error.message}</p>
            </div>
          ) : !userData ? (
            <div className='flex h-full flex-col items-center justify-center gap-1'>
              <p className='text-destructive'>No data found for this user.</p>
            </div>
          ) : (
            <>
              <Section
                avatar={userData.avatar || undefined}
                options={accountDetails}
              />
              {userData?.ip_addresses && userData.ip_addresses.length > 0 && (
                <Section
                  title='IP Addresses'
                  options={userData.ip_addresses.map(ip => ({
                    label: (
                      <p
                        className={cn('text-sm font-semibold text-green-500', {
                          'text-destructive': ip.is_blocked
                        })}
                      >
                        {ip.value}
                      </p>
                    ),
                    rightContent: ip.country ? (
                      <GetCountryNameByIsoCode isoCode={ip.country} />
                    ) : (
                      <p className='text-foreground/50'>Unknown</p>
                    )
                  }))}
                />
              )}
              <ProtectedComponent permissionSlug='users_view'>
                <UserActions user={userData} />
                <Link
                  to={`/users/${userData.id}`}
                  className='w-full rounded-full bg-primary/10 py-2 text-center text-lg font-bold text-primary transition-all'
                >
                  View Full Details
                </Link>
              </ProtectedComponent>
            </>
          )}
        </section>
      </SheetContent>
    </Sheet>
  )
}

export default UserDetailsModal
