import {
  IAccountMetrics,
  IExchangeMetrics,
  IFeesMetrics,
  IMetricCard,
  IWalletMetrics
} from '@/types/metrics.interface'

import {
  BtcToUsd,
  formatBtc,
  formatInteger,
  formatNumber,
  msatsToSats,
  mstasToBtcAndFormat
} from '@/utils'

export const formatAccountMetrics = (data: IAccountMetrics) => {
  return [
    {
      title: 'Total Users',
      value: formatInteger(data.created_all),
      updatedAt: data.updated_at
    },
    {
      title: 'App Store Downloads',
      tabs: [
        {
          name: 'Week',
          value: formatInteger(data.downloads_apple_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.downloads_apple_month)
        },
        {
          name: 'All',
          value: formatInteger(data.downloads_apple_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Play Store Downloads',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.downloads_google_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.downloads_google_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.downloads_google_month)
        },
        {
          name: 'All',
          value: formatInteger(data.downloads_google_all)
        }
      ],
      subtitle: 'Google Play',
      updatedAt: data.updated_at
    },
    {
      title: 'APK Downloads',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.downloads_apk_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.downloads_apk_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.downloads_apk_month)
        },
        {
          name: 'All',
          value: formatInteger(data.downloads_apk_all)
        }
      ],
      subtitle: 'APK',
      updatedAt: data.updated_at
    },
    {
      title: 'Referred',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.referred_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.referred_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.referred_month)
        },
        {
          name: 'All',
          value: formatInteger(data.referred_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: (
        <span>
          Satoshi<b className='text-orange-500'>+</b>
        </span>
      ),
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.satoshi_plus_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.satoshi_plus_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.satoshi_plus_month)
        },
        {
          name: 'All',
          value: formatInteger(data.satoshi_plus_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'KYC Verified',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.kyc_verified_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.kyc_verified_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.kyc_verified_month)
        },
        {
          name: 'All',
          value: formatInteger(data.kyc_verified_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Created Users',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.created_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.created_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.created_month)
        },
        {
          name: 'All',
          value: formatInteger(data.created_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Active Users',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.active_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.active_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.active_month)
        },
        {
          name: 'All',
          value: formatInteger(data.active_all)
        }
      ],
      className: 'xl:col-span-2',
      updatedAt: data.updated_at
    },
    {
      title: 'App Opens',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.app_opens_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.app_opens_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.app_opens_month)
        },
        {
          name: 'All',
          value: formatInteger(data.app_opens_all)
        }
      ],
      className: 'xl:col-span-2',
      updatedAt: data.updated_at
    }
  ] as IMetricCard[]
}

export const formatExchangeMetrics = (data: IExchangeMetrics) => {
  return [
    {
      title: 'BTC Under Custody',
      value: `${formatBtc(data.custodied_btc)} BTC`,
      subtitle: `$${BtcToUsd(data.custodied_btc, data.btc_price).toFormat(2)}`,
      valueColor: 'orange',
      titleColor: 'orange',
      className: 'xl:col-span-3',
      updatedAt: data.updated_at
    },
    {
      title: 'USD Under Custody',
      value: `$${formatNumber(data.custodied_usd)}`,
      valueColor: 'blue',
      titleColor: 'blue',
      className: 'xl:col-span-2',
      updatedAt: data.updated_at
    },
    {
      title: 'Buy Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_buy_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_buy_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_buy_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_buy_usd_all)}`
        }
      ],
      valueColor: 'green',
      updatedAt: data.updated_at
    },
    {
      title: 'Sell Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_sell_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_sell_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_sell_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_sell_usd_all)}`
        }
      ],
      valueColor: 'purple',
      updatedAt: data.updated_at
    },
    {
      title: 'Withdrawals Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.withdrawal_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.withdrawal_usd_week)}`,
          limit: `$${formatNumber(data.limits.funding_withdrawal_weekly_limit)}`,
          progress:
            data.withdrawal_usd_week /
            Number(data.limits.funding_withdrawal_weekly_limit)
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.withdrawal_usd_month)}`,
          limit: `$${formatNumber(data.limits.funding_withdrawal_monthly_limit)}`,
          progress:
            data.withdrawal_usd_month /
            Number(data.limits.funding_withdrawal_monthly_limit)
        },
        {
          name: 'All',
          value: `$${formatNumber(data.withdrawal_usd_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Deposits (ACH) Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.deposit_ach_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.deposit_ach_usd_week)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_weekly_limit)}`,
          progress:
            data.deposit_ach_usd_week /
            Number(data.limits.funding_deposit_weekly_limit)
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.deposit_ach_usd_month)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_monthly_limit)}`,
          progress:
            data.deposit_ach_usd_month /
            Number(data.limits.funding_deposit_monthly_limit)
        },
        {
          name: 'All',
          value: `$${formatNumber(data.deposit_ach_usd_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Deposits (Wire) Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.deposit_wire_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.deposit_wire_usd_week)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_weekly_limit)}`,
          progress:
            data.deposit_wire_usd_week /
            Number(data.limits.funding_deposit_weekly_limit)
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.deposit_wire_usd_month)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_monthly_limit)}`,
          progress:
            data.deposit_wire_usd_month /
            Number(data.limits.funding_deposit_monthly_limit)
        },
        {
          name: 'All',
          value: `$${formatNumber(data.deposit_wire_usd_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Buy Avg',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_buy_usd_1h / data.trades_buy_count_1h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_buy_usd_24h / data.trades_buy_count_24h)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_buy_usd_24h / data.trades_buy_count_24h)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_buy_usd_all / data.trades_buy_count_all)}`
        }
      ],
      valueColor: 'green',
      updatedAt: data.updated_at
    },
    {
      title: 'Sell Avg',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_sell_usd_1h / data.trades_sell_count_1h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_sell_usd_24h / data.trades_sell_count_24h)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_sell_usd_24h / data.trades_sell_count_24h)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_sell_usd_all / data.trades_sell_count_all)}`
        }
      ],
      valueColor: 'purple',
      updatedAt: data.updated_at
    },
    {
      title: 'Withdrawal Avg',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.withdrawal_usd_1h / data.withdrawal_count_1h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.withdrawal_usd_24h / data.withdrawal_count_24h)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.withdrawal_usd_24h / data.withdrawal_count_24h)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.withdrawal_usd_all / data.withdrawal_count_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Deposit (ACH) Avg',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.deposit_ach_usd_1h / data.deposit_ach_count_1h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.deposit_ach_usd_24h / data.deposit_ach_count_24h)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.deposit_ach_usd_24h / data.deposit_ach_count_24h)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.deposit_ach_usd_all / data.deposit_ach_count_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Deposit (Wire) Avg',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.deposit_wire_usd_1h / data.deposit_wire_count_1h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.deposit_wire_usd_24h / data.deposit_wire_count_24h)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.deposit_wire_usd_24h / data.deposit_wire_count_24h)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.deposit_wire_usd_all / data.deposit_wire_count_all)}`
        }
      ],
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Buy Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.trades_buy_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.trades_buy_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.trades_buy_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.trades_buy_count_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Sell Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.trades_sell_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.trades_sell_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.trades_sell_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.trades_sell_count_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Withdrawal Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.withdrawal_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.withdrawal_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.withdrawal_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.withdrawal_count_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Deposit (ACH) Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.deposit_ach_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.deposit_ach_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.deposit_ach_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.deposit_ach_count_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Deposit (Wire) Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.deposit_wire_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.deposit_wire_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.deposit_wire_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.deposit_wire_count_all)
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Book Transfers Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.transfers_book_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.transfers_book_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.transfers_book_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.transfers_book_count_all)
        }
      ],
      className: 'xl:col-span-2',
      updatedAt: data.updated_at
    },
    {
      title: 'Crypto Transfers Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.transfers_crypto_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.transfers_crypto_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.transfers_crypto_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.transfers_crypto_count_all)
        }
      ],
      className: 'xl:col-span-2',
      updatedAt: data.updated_at
    },
    {
      title: 'Lighning Transfers Count',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.transfers_lightning_count_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.transfers_lightning_count_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.transfers_lightning_count_month)
        },
        {
          name: 'All',
          value: formatInteger(data.transfers_lightning_count_all)
        }
      ],
      updatedAt: data.updated_at
    }
  ] as IMetricCard[]
}

export const formatWalletMetrics = (data: IWalletMetrics) => {
  return [
    {
      title: 'Total Balance',
      value: `${mstasToBtcAndFormat(data.total_balance_msats)} BTC`,
      subtitle: `$${formatNumber(data.total_balance_usd)}`,
      className: 'md:col-span-2',
      valueColor: 'purple',
      titleColor: 'purple',
      updatedAt: data.updated_at
    },
    {
      title: 'Volume Sent',
      tabs: [
        {
          name: 'Day',
          value: `${mstasToBtcAndFormat(data.volume_sent_msats_24h)} BTC`,
          subtitle: `$${formatNumber(data.volume_sent_usd_24h)}`
        },
        {
          name: 'Week',
          value: `${mstasToBtcAndFormat(data.volume_sent_msats_week)} BTC`,
          subtitle: `$${formatNumber(data.volume_sent_usd_week)}`
        },
        {
          name: 'Month',
          value: `${mstasToBtcAndFormat(data.volume_sent_msats_month)} BTC`,
          subtitle: `$${formatNumber(data.volume_sent_usd_month)}`
        },
        {
          name: 'All',
          value: `${mstasToBtcAndFormat(data.volume_sent_msats_all)} BTC`,
          subtitle: `$${formatNumber(data.volume_sent_usd_all)}`
        }
      ],
      className: 'md:col-span-2',
      valueColor: 'green',
      updatedAt: data.updated_at
    },
    {
      title: 'Volume Received',
      tabs: [
        {
          name: 'Day',
          value: `${mstasToBtcAndFormat(data.volume_received_msats_24h)} BTC`,
          subtitle: `$${formatNumber(data.volume_received_usd_24h)}`
        },
        {
          name: 'Week',
          value: `${mstasToBtcAndFormat(data.volume_received_msats_week)} BTC`,
          subtitle: `$${formatNumber(data.volume_received_usd_week)}`
        },
        {
          name: 'Month',
          value: `${mstasToBtcAndFormat(data.volume_received_msats_month)} BTC`,
          subtitle: `$${formatNumber(data.volume_received_usd_month)}`
        },
        {
          name: 'All',
          value: `${mstasToBtcAndFormat(data.volume_received_msats_all)} BTC`,
          subtitle: `$${formatNumber(data.volume_received_usd_all)}`
        }
      ],
      className: 'md:col-span-2',
      valueColor: 'orange',
      updatedAt: data.updated_at
    },
    {
      title: 'Payments Sent',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.payments_sent_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.payments_sent_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.payments_sent_month)
        },
        {
          name: 'All',
          value: formatInteger(data.payments_sent_all)
        }
      ],
      className: 'xl:col-span-3',
      valueColor: 'blue',
      updatedAt: data.updated_at
    },
    {
      title: 'Payments Received',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.payments_received_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.payments_received_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.payments_received_month)
        },
        {
          name: 'All',
          value: formatInteger(data.payments_received_all)
        }
      ],
      className: 'xl:col-span-3',
      valueColor: 'blue',
      updatedAt: data.updated_at
    }
  ] as IMetricCard[]
}

export const formatFeesMetrics = (data: IFeesMetrics) => {
  return [
    {
      title: 'Exchange Buy',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.exchange_buy_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.exchange_buy_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.exchange_buy_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.exchange_buy_all)}`
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Exchange Sell',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.exchange_sell_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.exchange_sell_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.exchange_sell_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.exchange_sell_all)}`
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Exchange Send P2P',
      tabs: [
        {
          name: 'Day',
          value: `${msatsToSats(data.exchange_send_p2p_24h)} sats`
        },
        {
          name: 'Week',
          value: `${msatsToSats(data.exchange_send_p2p_week)} sats`
        },
        {
          name: 'Month',
          value: `${msatsToSats(data.exchange_send_p2p_month)} sats`
        },
        {
          name: 'All',
          value: `${msatsToSats(data.exchange_send_p2p_all)} sats`
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Exchange Send Lightning',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_24h)))} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_week)))} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_month)))} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_all)))} sats`
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Exchange Withdrawal',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_24h)))} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_week)))} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_month)))} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_all)))} sats`
        }
      ],
      updatedAt: data.updated_at
    },
    {
      title: 'Starter Send Lightning',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(data.starter_send_lightning_24h)} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(data.starter_send_lightning_week)} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(data.starter_send_lightning_month)} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(data.starter_send_lightning_all)} sats`
        }
      ],
      updatedAt: data.updated_at
    }
  ] as IMetricCard[]
}
