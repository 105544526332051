import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import GetCountryNameByIsoCode from '@/components/features/GetCountryNameFromIsoCode'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { buttonVariants } from '@/components/ui/Button'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'

import { cn, msatsToSats } from '@/utils'

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return <UserDetailsModal fromOtherPage user={row.original} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'refer_claimable_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimable' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-primary'>
          {msatsToSats(row.original.refer_claimable_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'referrals',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Referred' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.referrals.length || 0} users
        </p>
      )
    }
  },
  {
    accessorKey: 'refer_earnings_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Total Earned' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-green550'>
          {Number(msatsToSats(row.original.refer_earnings_msats)) +
            Number(msatsToSats(row.original.refer_claimable_msats))}{' '}
          sats
        </p>
      )
    }
  },
  {
    accessorKey: 'country',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Country' />
    },
    cell: ({ row }) => {
      const account = row.original

      if (
        account.ip_addresses &&
        account.ip_addresses[0] &&
        account.ip_addresses[0].country
      ) {
        return (
          <GetCountryNameByIsoCode isoCode={account.ip_addresses[0].country} />
        )
      }

      return <p className='text-foreground/50'>Uknown</p>
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{date.toLocaleDateString()}</p>
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Actions' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <Link
            to={`/refer/users/${row.original.id}`}
            className={cn(
              buttonVariants({
                variant: 'edit'
              })
            )}
          >
            View Referrals
          </Link>
        </div>
      )
    },
    enableSorting: false
  }
]
