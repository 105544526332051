const color = [
  'rgba(75,192,192,1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(75,192,192,1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]

export const defaultDatasetOptions = () => {
  const colorIndex = Math.floor(Math.random() * color.length)

  return {
    fill: false,
    borderColor: color[colorIndex],
    tension: 0.1,
    pointRadius: 3,
    pointHoverRadius: 5,
    hidden: true
  }
}
