import { ColumnDef } from '@tanstack/react-table'
import BigNumber from 'bignumber.js'
import toast from 'react-hot-toast'

import GetCountryNameByIsoCode from '@/components/features/GetCountryNameFromIsoCode'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'

import { formatInteger, msatsToSats } from '@/utils'

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return <UserDetailsModal fromOtherPage user={row.original} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'daily_earn_rate_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='DER' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.daily_earn_rate_msats
            ? formatInteger(
                String(msatsToSats(row.original.daily_earn_rate_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'accrued_balance_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Future Pay' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.accrued_balance_msats
            ? formatInteger(
                String(msatsToSats(row.original.accrued_balance_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'streak_count',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Streak' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.streak_count
            ? formatInteger(String(row.original.streak_count))
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'rank',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Rank' />
    },
    cell: ({ row }) => row.original.rank
  },
  {
    accessorKey: 'earnings_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimed' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.earnings_msats
            ? formatInteger(String(msatsToSats(row.original.earnings_msats)))
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'earned_unclaimed_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimable' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.earned_unclaimed_msats
            ? formatInteger(
                String(msatsToSats(row.original.earned_unclaimed_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'earned',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Earned' />
    },
    cell: ({ row }) => {
      const earned = BigNumber(row.original.earned_unclaimed_msats).plus(
        row.original.earnings_msats
      )
      return (
        <p className='text-foreground/50'>
          {earned ? formatInteger(String(msatsToSats(earned.toString()))) : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'country',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Country' />
    },
    cell: ({ row }) => {
      const account = row.original

      if (
        account.ip_addresses &&
        account.ip_addresses[0] &&
        account.ip_addresses[0].country
      ) {
        return (
          <GetCountryNameByIsoCode isoCode={account.ip_addresses[0].country} />
        )
      }

      return <p className='text-foreground/50'>Uknown</p>
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{date.toLocaleDateString()}</p>
    }
  }
]
