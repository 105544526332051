import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IFlashPayment } from '@/types/flash-payment.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const columns: ColumnDef<IFlashPayment>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'tx_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Tx ID' />
    },
    cell: ({ row }) => {
      if (!row.original.tx_id) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.tx_id}`)
            toast.success('Tx ID Copied to clipboard')
          }}
        >
          {formatId(row.original.tx_id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p>{row.original.type}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'side',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Side' />
    },
    cell: ({ row }) => {
      return <p>{row.original.side}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return `${msatsToSats(String(row.original.amount_msats))} sats`
    },
    enableSorting: false
  },
  {
    accessorKey: 'fee_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Fee' />
    },
    cell: ({ row }) => {
      return `${msatsToSats(String(row.original.fee_msats))} sats`
    },
    enableSorting: false
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.description || '-'}</p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'destination',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Destination' />
    },
    cell: ({ row }) => {
      if (!row.original.destination) {
        return <p className='text-foreground/50'>-</p>
      }
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.destination}`)
            toast.success('Destination Copied to clipboard')
          }}
        >
          {formatId(row.original.destination)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'bolt11',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Bolt11' />
    },
    cell: ({ row }) => {
      if (!row.original.bolt11) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.bolt11}`)
            toast.success('Bolt11 Copied to clipboard')
          }}
        >
          {formatId(row.original.bolt11)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'preimage',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Preimage' />
    },
    cell: ({ row }) => {
      if (!row.original.preimage) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.preimage}`)
            toast.success('Preimage Copied to clipboard')
          }}
        >
          {formatId(row.original.preimage)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'swap_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Swap ID' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.swap_id || '-'}</p>
    }
  },
  {
    accessorKey: 'refund_tx_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Refund TX ID' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.refund_tx_id || '-'}</p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    },
    enableSorting: false
  }
]
