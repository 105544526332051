import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IReferralPayment } from '@/types/account/referral.interface'

import formatId from '@/utils/formatId'

import { formatNumber, msatsToSats } from '@/utils'

export const columns: ColumnDef<IReferralPayment>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'sender',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='From' />
    },
    cell: ({ row }) => {
      if (!row.original.sender) {
        return <p className='text-foreground/50'>-</p>
      }
      return <UserDetailsModal fromOtherPage user={row.original.sender} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'receiver',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='To' />
    },
    cell: ({ row }) => {
      if (!row.original.receiver) {
        return <p className='text-foreground/50'>-</p>
      }
      return <UserDetailsModal fromOtherPage user={row.original.receiver} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-purple400'>
          {msatsToSats(row.original.amount_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'amount_usd',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount USD' />
    },
    cell: ({ row }) => {
      return <p>{formatNumber(row.original.amount_usd)}</p>
    }
  },
  {
    accessorKey: 'bolt11',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Bolt11' />
    },
    cell: ({ row }) => {
      if (!row.original.bolt11) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.bolt11 || 'No Bolt11')
            toast.success('Bolt11 Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {formatId(row.original.bolt11)}
        </button>
      )
    }
  },
  {
    accessorKey: 'hash',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Hash' />
    },
    cell: ({ row }) => {
      if (!row.original.hash) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.hash || 'No Hash')
            toast.success('Hash Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {formatId(row.original.hash)}
        </button>
      )
    }
  },
  {
    accessorKey: 'memo',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Memo' />
    },
    cell: ({ row }) => {
      return <p>{row.original.memo || '-'}</p>
    }
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p>{row.original.type}</p>
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return <p>{new Date(row.original.created_at).toLocaleString()}</p>
    }
  }
]
