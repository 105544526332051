import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateOrUpdateService from './CreateOrUpdateService'
import { columns } from './columns'
import { ORGANIZATION_TABS } from '@/constants/tabs'

const StbaServicesPage: React.FC = () => {
  return (
    <Page
      permissionSlug='services_view'
      pageTitle='STBA Services'
      tabs={ORGANIZATION_TABS}
      header={<CreateOrUpdateService />}
    >
      <DataTable
        url='admin/stba-service'
        totalText='services'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default StbaServicesPage
