import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import { LoaderIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Page from '@/components/Page'
import FailureCodeComponent from '@/components/features/FailureCode'
import GetCountryNameByIsoCode from '@/components/features/GetCountryNameFromIsoCode'
import UserActions from '@/components/features/UserDetails/Actions'
import UserLimits from '@/components/features/UserDetails/Limits'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import {
  Section,
  SectionOption
} from '@/components/features/UserDetails/Section'
import UserUpdate from '@/components/features/UserDetails/Update'
import BackButton from '@/components/ui/ButtonBack'
import { DataTable } from '@/components/ui/DataTable'
import Menu from '@/components/ui/Menu'

import { IUser } from '@/types/account/user.interface'
import { AccountType, KycStatus } from '@/types/enums'

import formatId from '@/utils/formatId'
import { parseLimits } from '@/utils/parseLimits'
import { returnKycIcon } from '@/utils/return-kyc-icon'

import { fundingsColumns } from './transactions/exchange-fundings.columns'
import { tradesColumns } from './transactions/exchange-trades.columns'
import { transfersColumns } from './transactions/exchange-transfers.columns'
import { lightningColumns } from './transactions/starter-lightning.columns'
import { onchainColumns } from './transactions/starter-onchain.columns'
import api from '@/api'
import { cn, formatNumber, millisatoshisToBTC } from '@/utils'

const UserDetailsPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const { data, error, isLoading } = useQuery({
    queryKey: ['user-details', userId],
    queryFn: async () => {
      const resp = await api.get<IUser>(`/admin/users/${userId}/details`)

      return resp.data
    },
    refetchOnWindowFocus: false
  })

  const [selectedTab, setSelectedTab] = useState<
    'details' | 'actions' | 'stats' | 'limits' | 'transactions'
  >('details')

  const isVerified =
    data &&
    (data.kyc_status === KycStatus.VERIFIED ||
      data.kyc_status === KycStatus.VERIFIED_NO_SSN)
      ? true
      : false

  const hasStarter = data && data.type === AccountType.STARTER ? true : false

  const accountDetails = useMemo(() => {
    if (!data) return []

    const details: SectionOption[] = [
      {
        label: 'ID',
        rightContent: (
          <p className='long-text whitespace-nowrap text-sm font-semibold'>
            {data.id}
          </p>
        )
      },
      {
        label: 'Display Name',
        rightContent: data.display_name || '-'
      },
      { label: 'Username', rightContent: `@${data.username}` },
      { label: 'Email', rightContent: data.email },
      {
        label: 'Membership Type',
        rightContent:
          data.membership_type === 'REGULAR' ? 'Regular' : 'Satoshi Plus'
      },
      {
        label: 'Refered BY',
        rightContent: data.referred_by ? (
          <UserDetailsModal fromOtherPage user={data.referred_by} small />
        ) : (
          <p className='text-foreground/50'>No Referrer</p>
        )
      },
      {
        label: 'Devices',
        rightContent:
          data?.devices &&
          data.devices.filter(
            device => device.app_version || device.device_type
          ).length > 0 ? (
            <div className='flex flex-col gap-1'>
              {data.devices
                .filter(device => device.app_version || device.device_type)
                .map((device, index) => (
                  <p
                    key={index}
                    className={cn('text-end text-sm font-semibold', {
                      'border-b border-white/15':
                        index !==
                        data.devices.filter(
                          device => device.app_version || device.device_type
                        ).length -
                          1
                    })}
                  >
                    <b>{index + 1}.</b>{' '}
                    {device.app_version && (
                      <>
                        Version:{' '}
                        <b className='text-orange500'>{device.app_version}</b>{' '}
                        <br />
                      </>
                    )}
                    {device.device_type && (
                      <>
                        Device:{' '}
                        <b className='text-orange500'>{device.device_type}</b>
                      </>
                    )}
                  </p>
                ))}
            </div>
          ) : (
            '-'
          )
      },
      {
        label: 'Date Joined',
        rightContent: format(data.created_at, 'yyyy.MM.dd')
      },
      {
        label: 'KYC Status',
        rightContent: (
          <p
            className={cn(
              'flex items-center gap-1 text-sm font-semibold text-lime500',
              {
                'text-destructive': data.kyc_status === KycStatus.FAILED,
                'text-orange500': data.kyc_status === KycStatus.STARTED,
                'text-foreground/50': data.kyc_status === KycStatus.UNVERIFIED
              }
            )}
          >
            {returnKycIcon(data.kyc_status, 'size-5')}
            {data.kyc_status}
          </p>
        )
      }
    ]
    if (
      data.kyc_status === KycStatus.FAILED ||
      (data.kyc_failure_codes && data.kyc_failure_codes.length > 0)
    ) {
      details.push({
        label: 'KYC Reject Reason',
        rightContent:
          data.kyc_failure_codes && data.kyc_failure_codes.length > 0 ? (
            <div className='flex w-full max-w-[250px] flex-col gap-0.5 text-right'>
              {data.kyc_failure_codes.map(val => (
                <FailureCodeComponent key={val} failureCode={val} />
              ))}
            </div>
          ) : (
            '-'
          )
      })
    }

    if (
      data.kyc_status === KycStatus.VERIFIED ||
      data.kyc_status === KycStatus.VERIFIED_NO_SSN
    ) {
      details.push(
        {
          label: 'Legal Name',
          rightContent:
            data.first_name || data.last_name
              ? `${data.first_name} ${data.last_name}`
              : '-'
        },
        {
          label: 'State',
          rightContent: data.address_subdivision || '-'
        },
        {
          label: 'Country',
          rightContent: data.address_country_code || '-'
        }
      )
    }

    details.push({
      label: 'Account Type',
      rightContent: data.type
    })

    details.push({
      label: 'Is Blocked',
      rightContent: data.blocked ? 'Yes' : 'No'
    })
    if (data?.blocked) {
      details.push({
        label: 'Blocked Reason',
        rightContent: data.blocked_reason || '-'
      })
    }

    if (data?.type === AccountType.FLASH) {
      details.push({
        label: 'LNURL',
        rightContent: data.flash_lnurl ? (
          <button
            onClick={() => {
              navigator.clipboard.writeText(`${data.flash_lnurl}`)
              toast.success('ID Copied to clipboard')
            }}
          >
            {formatId(data.flash_lnurl)}
          </button>
        ) : (
          <p className='text-foreground/50'>No LNURL found</p>
        )
      })
    }

    return details
  }, [data])

  const starterStats = useMemo(() => {
    if (!data) return []

    if (!hasStarter) {
      return null
    }

    return [
      {
        label: 'BTC Balance',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.balance_msats || 0)).toFormat(8)} BTC`
      },
      {
        label: 'Total Send',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.total_sent_msats || 0)).toFormat(8)} BTC`
      },
      {
        label: 'Total Recieved',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.total_received_msats || 0)).toFormat(8)} BTC`
      }
    ]
  }, [data])

  const exchangeStats = useMemo(() => {
    if (!data) return []

    if (isVerified) {
      return [
        {
          label: 'BTC Balance',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.balance_btc || 0)).toFormat(8)} BTC`
        },
        {
          label: 'USD Balance',
          rightContent: `$${formatNumber(data.exchange_stats.balance_usd, '0.00')}`
        },
        {
          label: 'Total Sent',
          rightContent: `$${formatNumber(data.exchange_stats.total_send_usd, '0.00')}`
        },
        {
          label: 'Total Recieved',
          rightContent: `$${formatNumber(data.exchange_stats.total_received_usd, '0.00')}`
        },
        {
          label: 'Total Bought',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.total_bought_msats || 0)).toFormat(8)} BTC`
        },
        {
          label: 'Total Sold',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.total_sold_msats || 0)).toFormat(8)} BTC`
        }
      ]
    }

    return null
  }, [data])

  return (
    <Page permissionSlug='users_view' pageTitle='User Details'>
      {isLoading ? (
        <LoaderIcon className='mx-auto my-10 size-10' />
      ) : error || !data ? (
        <p>
          Error loading user details{' '}
          {error && (
            <>
              <br />
              {error.message}
            </>
          )}
        </p>
      ) : (
        <>
          <section
            className={cn(
              'flex w-full flex-col gap-1 border-b border-white/15 pb-2 md:gap-2 md:pb-4 lg:gap-4'
            )}
          >
            <BackButton />
            <div className='flex flex-row items-center gap-2'>
              {data?.avatar && (
                <img
                  src={data.avatar}
                  alt=''
                  className='size-16 rounded-full bg-gray900'
                />
              )}
              <div className='flex flex-col gap-1'>
                <p className='text-lg font-bold'>{data?.display_name}</p>
                <p className='text-foreground/50'>@{data?.username}</p>
              </div>
            </div>
          </section>
          <Menu
            tabsArray={[
              'details',
              'actions',
              'stats',
              'limits',
              'transactions'
            ]}
            setSelectedTab={tab => {
              setSelectedTab(
                tab as
                  | 'details'
                  | 'stats'
                  | 'actions'
                  | 'limits'
                  | 'transactions'
              )
            }}
            selectedTab={selectedTab}
          />
          <section
            className={cn({
              'grid w-full grid-cols-1 gap-2 px-2 lg:grid-cols-2 lg:gap-8 lg:px-0 xl:grid-cols-3':
                selectedTab !== 'transactions',
              'flex w-full flex-col gap-2 md:gap-4':
                selectedTab === 'transactions'
            })}
          >
            {selectedTab === 'details' && (
              <div className='flex flex-col gap-2'>
                <Section options={accountDetails} />
                {data?.ip_addresses && data.ip_addresses.length > 0 && (
                  <Section
                    title='IP Addresses'
                    options={data.ip_addresses.map(ip => ({
                      label: (
                        <p
                          className={cn(
                            'text-sm font-semibold text-green-500',
                            {
                              'text-destructive': ip.is_blocked
                            }
                          )}
                        >
                          {ip.value}
                        </p>
                      ),
                      rightContent: ip.country ? (
                        <GetCountryNameByIsoCode isoCode={ip.country} />
                      ) : (
                        <p className='text-foreground/50'>Unknown</p>
                      )
                    }))}
                  />
                )}
                <UserUpdate initial={data} />
              </div>
            )}
            {selectedTab === 'stats' && (
              <>
                {exchangeStats !== null ? (
                  <>
                    <Section title='Exchange' options={exchangeStats} />
                    <Section title='Starter' options={starterStats} />
                  </>
                ) : (
                  <Section title='Starter' options={starterStats} />
                )}
                {starterStats === null && exchangeStats === null && (
                  <p className='text-lg font-semibold text-foreground/50'>
                    No Stats available for this user
                  </p>
                )}
              </>
            )}
            {selectedTab === 'actions' && <UserActions user={data} noTitle />}
            {selectedTab === 'limits' && (
              <UserLimits
                limits={parseLimits(data.limits)}
                hasExchange={isVerified}
                hasStarter={hasStarter}
              />
            )}
            {data && selectedTab === 'transactions' && (
              <>
                {hasStarter ? (
                  <>
                    <DataTable
                      columns={lightningColumns}
                      data={data.transactions.starter.lightning}
                      url='lightning'
                      title='Starter Lightning'
                      totalText='latest lightning transactions'
                      tableClassName='!max-h-[400px]'
                    />
                    <DataTable
                      columns={onchainColumns}
                      data={data.transactions.starter.onchain}
                      url='onchain'
                      title='Starter Onchain'
                      totalText='latest onchain transactions'
                      tableClassName='!max-h-[400px]'
                    />
                  </>
                ) : null}
                {isVerified ? (
                  <>
                    <DataTable
                      columns={fundingsColumns}
                      data={data.transactions.exchange.fundings}
                      url='fundings'
                      title='Exchange Fundings'
                      totalText='latest fundings'
                      tableClassName='!max-h-[400px]'
                    />
                    <DataTable
                      columns={transfersColumns}
                      data={data.transactions.exchange.transfers}
                      url='transfers'
                      title='Exchange Transfers'
                      totalText='latest transfers'
                      tableClassName='!max-h-[400px]'
                    />
                    <DataTable
                      columns={tradesColumns}
                      data={data.transactions.exchange.trades}
                      url='trades'
                      title='Exchange Trades'
                      totalText='latest trades'
                      tableClassName='!max-h-[400px]'
                    />
                  </>
                ) : null}
                {!hasStarter && !isVerified && (
                  <p className='text-lg font-semibold text-foreground/50'>
                    No Transactions available for this user
                  </p>
                )}
              </>
            )}
          </section>
        </>
      )}
    </Page>
  )
}

export default UserDetailsPage
