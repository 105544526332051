import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import MutationActionButton from '@/components/features/MutationActionButton'
import { buttonVariants } from '@/components/ui/Button'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'
import Icon from '@/components/ui/Icon'

import { ISTBAService } from '@/types/stba-service.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdateService from './CreateOrUpdateService'

export const columns: ColumnDef<ISTBAService>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      return <p>{row.original.name}</p>
    }
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      if (!row.original.description) {
        return <p className='text-foreground/50'>N/A</p>
      }

      return <p>{row.original.description}</p>
    }
  },
  {
    accessorKey: 'aws_instance_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='AWS Ec2 ID' />
    },
    cell: ({ row }) => {
      if (!row.original.aws_instance_id) {
        return <p className='text-foreground/50'>N/A</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.aws_instance_id}`)
            toast.success('Value Copied to clipboard')
          }}
        >
          {row.original.aws_instance_id}
        </button>
      )
    }
  },
  {
    accessorKey: 'base_url',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Base URL' />
    },
    cell: ({ row }) => {
      return (
        <Link
          to={row.original.base_url}
          className='text-primary'
          target='_blank'
        >
          {row.original.base_url}
        </Link>
      )
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      if (row.original.status === null) {
        return (
          <p className='text-foreground/50'>
            {row.original.status_endpoint ? 'N/A' : 'Not Configured'}
          </p>
        )
      }

      return row.original.status ? (
        <Icon icon='Approved' className='size-6' />
      ) : (
        <Icon icon='Rejected' className='size-6' />
      )
    }
  },
  {
    accessorKey: 'aws_status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='AWS Status' />
    },
    cell: ({ row }) => {
      if (row.original.aws_status === null) {
        return (
          <p className='text-foreground/50'>
            {row.original.aws_instance_id ? 'N/A' : 'Not Configured'}
          </p>
        )
      }

      return (
        <p className='flex items-center gap-1'>
          {row.original.aws_status === 'running' ? (
            <Icon icon='Approved' className='size-6' />
          ) : (
            <Icon icon='Rejected' className='size-6' />
          )}
          ({row.original.aws_status})
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          {row.original.logs_endpoint && (
            <Link
              to={`/organization/stba-services/${row.original.id}`}
              className={buttonVariants({ variant: 'edit' })}
            >
              Logs
            </Link>
          )}
          <CreateOrUpdateService initial={row.original} />
          <MutationActionButton
            url={`/admin/stba-service/${row.original.id}`}
            description='Are you sure you want to delete this service from the admin panel?'
            permissionSlug='services_delete'
            successMessage='Service deleted successfully'
            errorMessage='Failed to delete service'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
