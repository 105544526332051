import { useState } from 'react'

import Page from '@/components/Page'
import ReferMetrics from '@/components/features/Metrics/Refer'
import SelectCountryIso, {
  CountryIso
} from '@/components/features/SelectCountryIso'
import { DataTable } from '@/components/ui/DataTable'

import { columns } from './columns'
import { REFER_TABS } from '@/constants/tabs'

const ReferUsersPage: React.FC = () => {
  const [country, setCountry] = useState<CountryIso | 'all' | undefined>(
    undefined
  )

  return (
    <Page
      permissionSlug='refer_view'
      pageTitle='Refer Transactions'
      tabs={REFER_TABS}
    >
      <ReferMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='users'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        method='POST'
        body={{ country }}
        defaultSortBy='referrals'
        defaultSortOrder='desc'
      >
        <SelectCountryIso country={country} setCountry={setCountry} />
      </DataTable>
    </Page>
  )
}

export default ReferUsersPage
