import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast, { Toast, ToastOptions } from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/AlertDialog'

import { Button } from '../ui/Button'
import Field from '../ui/Field'

import api from '@/api'

export interface MutationActionButtonProps {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  body?: Record<string, unknown>
  onSuccess?: (data?: unknown) => void
  buttonTitle?: string
  buttonClassName?: string
  title?: string
  description?: string
  successMessage?: Toast['message']
  successMessageOptions?: ToastOptions
  successMessageShown?: boolean
  errorMessage?: string
  permissionSlug?: string
  requireBlockedReason?: boolean
}

const MutationActionButton: React.FC<MutationActionButtonProps> = ({
  url,
  method = 'DELETE',
  body,
  onSuccess,
  buttonClassName,
  buttonTitle = 'Delete',
  title = 'Delete Item',
  description = 'Are you sure you want to delete this item?',
  successMessage = 'Item deleted successfully',
  successMessageOptions,
  successMessageShown = true,
  errorMessage = 'An error occurred while deleting the item',
  permissionSlug,
  requireBlockedReason = false
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['delete-item', url],
    mutationFn: async (data: { blocked_reason: string }) => {
      const resp = await api.request({
        method,
        url,
        data: {
          ...body,
          ...data
        }
      })
      return resp.data
    },
    onSuccess: data => {
      queryClient.invalidateQueries()
      successMessageShown &&
        toast.success(successMessage, successMessageOptions)
      if (onSuccess) {
        reset()
        onSuccess(data)
      }
      setIsOpen(false)
    },
    onError: () => {
      toast.error(errorMessage)
    }
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<{
    blocked_reason: string
  }>({
    mode: 'onChange',
    defaultValues: {
      blocked_reason: ''
    }
  })

  const onSubmit: SubmitHandler<{
    blocked_reason: string
  }> = async data => mutate(data)

  return (
    <ProtectedComponent permissionSlug={permissionSlug}>
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogTrigger asChild>
          <Button
            variant={'destructive'}
            disabled={isPending}
            className={buttonClassName}
          >
            {buttonTitle}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            {requireBlockedReason && (
              <Field
                title='Block Reason'
                placeholder='Enter the reason for blocking'
                {...register('blocked_reason', {
                  required: 'This field is required'
                })}
                error={errors.blocked_reason?.message}
              />
            )}
          </form>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isPending}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ProtectedComponent>
  )
}

export default MutationActionButton
