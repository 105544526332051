import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select'

export enum CountryIso {
  US = 'US'
}

interface Props {
  country: CountryIso | 'all' | undefined
  setCountry: (status: CountryIso | 'all' | undefined) => void
}

const SelectCountryIso: React.FC<Props> = ({ country, setCountry }) => {
  return (
    <Select
      value={country}
      onValueChange={val => {
        if (val === undefined) {
          setCountry(undefined)
          return
        }
        setCountry(val as CountryIso | undefined)
      }}
    >
      <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
        <SelectValue placeholder='Select Country' />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={'all'}>All</SelectItem>
        <SelectItem value={CountryIso.US}>United States</SelectItem>
      </SelectContent>
    </Select>
  )
}

export default SelectCountryIso
