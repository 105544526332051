import Page from '@/components/Page'
import ReferMetrics from '@/components/features/Metrics/Refer'
import { DataTable } from '@/components/ui/DataTable'

import { columns } from './columns'
import { REFER_TABS } from '@/constants/tabs'

const ReferClaimsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='refer_view'
      pageTitle='Refer Transactions'
      tabs={REFER_TABS}
    >
      <ReferMetrics />
      <DataTable
        url='admin/refer/claims'
        totalText='transactions'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default ReferClaimsPage
