import { ColumnDef } from '@tanstack/react-table'
import { formatDate } from 'date-fns'
import toast from 'react-hot-toast'

import MutationActionButton from '@/components/features/MutationActionButton'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IReferral } from '@/types/account/referral.interface'

import formatId from '@/utils/formatId'

export const columns: ColumnDef<IReferral>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return formatId(row.original.id)
    },
    enableSorting: false
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'referral',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Referral Account' />
    },
    cell: ({ row }) => {
      if (!row.original.referral) {
        return <p className='text-foreground/50'>-</p>
      }

      return <UserDetailsModal fromOtherPage user={row.original.referral} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <p className='text-foreground/50'>{formatDate(date, 'yyyy.MM.dd')}</p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Actions' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <MutationActionButton
            url={`/admin/refer/${row.original.account_id}/referrals`}
            body={{
              email: row.original.email
            }}
            method='PUT'
            buttonTitle='Remove'
            description='Are you sure you want to remove this referral from the account?'
            permissionSlug='refer_update'
            successMessage='Referral removed'
            errorMessage='Error removing referral'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
