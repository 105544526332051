import Page from '@/components/Page'

import AccountsMetricsChart from './AccountsMetricsChart'
import ExchangeMetricsChart from './ExchangeMetricsChart'
import WalletMetricsChart from './WalletsMetricsChart'
import { METRICS_TABS } from '@/constants/tabs'

const MetricsChartsPage: React.FC = () => {
  return (
    <Page permissionSlug='metrics_view' pageTitle='Metrics' tabs={METRICS_TABS}>
      <section className='grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-4'>
        <AccountsMetricsChart />
        <ExchangeMetricsChart />
        <WalletMetricsChart wallet='flash' />
        <WalletMetricsChart wallet='stash' />
        <WalletMetricsChart wallet='starter' />
      </section>
    </Page>
  )
}

export default MetricsChartsPage
