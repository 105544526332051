import { useState } from 'react'

import Page from '@/components/Page'
import EarnMetrics from '@/components/features/Metrics/Earn'
import SelectCountryIso, {
  CountryIso
} from '@/components/features/SelectCountryIso'
import { DataTable } from '@/components/ui/DataTable'

import { columns } from './columns'
import { EARN_TABS } from '@/constants/tabs'

const EarnUsersPage: React.FC = () => {
  const [country, setCountry] = useState<CountryIso | 'all' | undefined>(
    undefined
  )

  return (
    <Page permissionSlug='earn_view' pageTitle='Earn' tabs={EARN_TABS}>
      <EarnMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='rank'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        defaultSortBy='rank'
        defaultSortOrder='asc'
        body={{ country }}
        method='POST'
      >
        <SelectCountryIso country={country} setCountry={setCountry} />
      </DataTable>
    </Page>
  )
}

export default EarnUsersPage
