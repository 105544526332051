import { ColumnDef } from '@tanstack/react-table'

import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { ILog } from '@/types/log.interface'

import { cn } from '@/utils'

export const columns: ColumnDef<ILog>[] = [
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return (
        <p
          className={cn({
            'text-red-500': row.original.type === 'response_error'
          })}
        >
          {row.original.type === 'response_error' ? 'Error' : 'Info'}
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.timestamp).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'request_url',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request URL' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_url}</p>
    }
  },
  {
    accessorKey: 'request_method',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Method' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_method}</p>
    }
  },
  {
    accessorKey: 'request_ip',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request IP' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_ip}</p>
    }
  },
  {
    accessorKey: 'request_user_agent',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request User Agent' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.request_user_agent}</p>
      )
    }
  },
  {
    accessorKey: 'response_status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Response Status' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.response_status}</p>
      )
    }
  },
  {
    accessorKey: 'response_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Response Code' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.response_code}</p>
    }
  },
  {
    accessorKey: 'response_message',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Response Message' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.response_message}</p>
      )
    }
  },
  {
    accessorKey: 'request_origin',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Origin' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_origin}</p>
    }
  },
  {
    accessorKey: 'request_query',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Query' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_query}</p>
    }
  },
  {
    accessorKey: 'request_params',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Params' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_params}</p>
    }
  },
  {
    accessorKey: 'request_headers',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Headers' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.request_headers}</p>
      )
    }
  },
  {
    accessorKey: 'request_body',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Request Body' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.request_body}</p>
    }
  }
]
